import React, { useState, useEffect } from "react";
import Layout from "../../components/chat-channel/Layout";
import AnchorLink from "react-anchor-link-smooth-scroll";
import Container from "../../components/chat-channel/Container";
import TitleAndMetaTags from "../../components/common/TitleAndHeader";
import { RequestForm } from "../../components/form";
import { OnScrollPopup } from "../conversational-ai-platform/hr-chatbot";
import {GoToPopup} from "../homepage"
import "react-accessible-accordion/dist/fancy-example.css";

const topImage = require("../../assets/images/market_ui/slack/blog_image.png");
const image1 = require("../../assets/images/market_ui/slack/img_1.png");
const image2 = require("../../assets/images/market_ui/slack/img_2.png");
const image3 = require("../../assets/images/market_ui/slack/img_3.png");
const image4 = require("../../assets/images/market_ui/slack/img_4.png");

const BlogHeader = require("../../assets/img/blogs/header_blog_images_8.png.webp");
const Section1 = require("../../assets/img/blogs/blog_whats_new.png.webp");
const Section2 = require("../../assets/img/blogs/chatbot_best_2.png");
const Section3 = require("../../assets/img/blogs/chatbot_best_3.jpeg");
const Section4 = require("../../assets/img/blogs/Blog_Chatbot Best Practices_1.jpg");
const Section5 = require("../../assets/img/blogs/chatbot_best_5.png");
const Section6 = require("../../assets/img/blogs/chatbot_best_6.png");
const Section7 = require("../../assets/img/blogs/chatbot_best_7.png");
const Section8 = require("../../assets/img/blogs/chatbot_best_8.png");
const SectionLast = require("../../assets/img/blogs/chatbot_best_final.gif");

const ldSchema = {
  "@context": "https://schema.org",
  "@type": "BlogPosting",
  mainEntityOfPage: {
    "@type": "WebPage",
    "@id":
      "https://workativ.com/conversational-ai-platform/slack-chatbot-guide",
  },
  headline: "Slack Chatbot Guide for your Business",
  image: "",
  author: {
    "@type": "Organization",
    name: "Team Workativ",
  },
  publisher: {
    "@type": "Organization",
    name: "Workativ",
    logo: {
      "@type": "ImageObject",
      url:
        "https://workativ.com/static/workativ-logo-5eea21f2dcd20bbbbf52debb41c0f26e.png",
    },
  },
  datePublished: "2021-08-05",
  dateModified: "2021-08-05",
};

export default function FeatureHomePage() {
  return (
    <>
      <TitleAndMetaTags
        title="Why we built Workativ Assistant: To transform workplace support"
        description="Workativ Assistant helps companies reduce cost and increase productivity by providing conversational ai based workflow automation solution for employee support. "
        keywords={[
          "Slack Chatbot, Automation, AI Chatbot",
          "workativ free trial",
          "Slack chatbot free trial",
          "Slack Chatbot",
        ]}
        ogImage={topImage}
        schema={ldSchema}
      />
      <section className="market_main_wrapper">
        <Container>
          <Layout backgroundColor={"_bg_market"} logoFor="ASSISTANT">
            <div className="grid_container_chatbot">
              <div className="container">
                <div className="row">
                  <div className="grid_container_chatbot_wrapper">
                    <div className="grid__wrapper">
                      <div className="col-lg-12 col-md-12 col-xs-12 trial_header_left">
                        <div className="main-slider-left-market">
                          <h1 className="font-page-header ">
                            We had one big reason to build Workativ Assistant:
                            To transform workplace support
                          </h1>
                        </div>
                      </div>
                      <img src={BlogHeader} />
                      <div className=" mt-5 market_wrapper_page">
                        <p class="font-section-normal-text line-height-2">
                          <a href="https://workativ.com/conversational-ai-platform/conversational-ai-chatbot">
                            Workplace IT support
                          </a>{" "}
                          can be as simple as fixing a computer, or as
                          complicated as setting up a whole new server system.
                          Employees could reach out through different
                          communication channels; phone calls, emails, or texts.
                          And if their work is heavily dependent on IT systems
                          (as is usually the case), they expect prompt and
                          precise response: which is why having to identify and
                          explain themselves repeatedly, waiting long hours for
                          troubleshooting steps, or getting their requests
                          missed can take a toll on their productivity.
                        </p>

                        <p class="font-section-normal-text line-height-2">
                          This is what happens when you face limitations in
                          technology; there’s only so much humans can handle. IT
                          managers are often under pressure as requests pile up.
                          This may lead to their overlooking crucial details,
                          errors in judgement, and mental exhaustion.
                        </p>
                      </div>

                      <NocodeWrapper />

                      <div>
                        <h6 class="font-section-sub-header-small">
                          The reason we built Workativ Assistant.
                        </h6>
                        <p class="font-section-normal-text line-height-2">
                          Workativ is thrilled to announce Workativ Assistant –
                          a cognitive workplace automation platform designed for
                          IT teams to streamline services and provide
                          outstanding workplace IT support for employees.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          We’ve bundled process automation builder and dialogue
                          builder into a single platform that makes it easy to
                          try, build and deploy an intelligent virtual assistant
                          at a very low cost. Workativ Assistant helps you
                          automate time-consuming workplace IT tasks and provide
                          self-service support via virtual assistants, thereby
                          offering round-the-clock IT support for employees.
                        </p>
                        <h6 class="font-section-sub-header-small">
                          Offer employees the support they want, when they want.
                        </h6>

                        <p class="font-section-normal-text-medium line-height-2">
                          Employees expect real-time assistance regardless of
                          where they are and what channel they choose – a
                          challenge every organisation should keep up with.
                          Demands keep growing as employees believe companies
                          need to make an effort to provide seamless employee
                          service experience.
                        </p>

                        <p class="font-section-normal-text line-height-2">
                          This involves{" "}
                          <a href="https://workativ.com/conversational-ai-platform/chatbot-channels">
                            providing employees 24×7 support on their
                            smartphones through their favourite chat channels.{" "}
                          </a>
                          With Workativ Assistant, you have the flexibility to
                          extend exactly the type of support experience your
                          employees expect.
                        </p>

                        <p class="font-section-normal-text line-height-2">
                          Boost your IT team’s efficiency – make your IT
                          Helpdesk more intelligent. Workativ Assistant delivers
                          an intuitive interface, a revolutionary new take on
                          SLAs, customizable workflows to automate
                          time-consuming workplace tasks, and more.
                        </p>

                        <p class="font-section-normal-text-medium line-height-2">
                          It integrates with various communication channels like
                          <a href="https://workativ.com/conversational-ai-platform/slack-chatbot">
                            {" "}
                            Slack,
                          </a>
                          <a href="https://workativ.com/conversational-ai-platform/ms-teams-bot">
                            {" "}
                            Teams,
                          </a>{" "}
                          Skype, and even your existing portal.
                        </p>

                        <p class="font-section-normal-text line-height-2">
                          Workativ Assistant helps IT teams support employees
                          faster than ever. With this product, we’ve gone beyond
                          existing workplace support platforms and added
                          Helpdesk-specific capabilities such as:
                        </p>
                        <ul className="font-section-small-text">
                          <li
                            className="font-section-small-text
"
                          >
                            Simple Easy to Use interface (EUS)
                          </li>
                          <li
                            className="font-section-small-text
"
                          >
                            Automation Catalogue
                          </li>
                          <li
                            className="font-section-small-text
"
                          >
                            Conversational forms
                          </li>
                          <li
                            className="font-section-small-text
"
                          >
                            Flexible Digital Experience
                          </li>
                          <li
                            className="font-section-small-text
"
                          >
                            Automation Builder
                          </li>
                          <li
                            className="font-section-small-text
"
                          >
                            Dialogue Builder
                          </li>
                        </ul>

                        <p class="font-section-normal-text line-height-2">
                          The result is a modern, flexible workplace IT support
                          experience for both employees and your IT Helpdesk
                          team. You’ll delight your employees, improve overall
                          productivity, and drive business performance. Workativ
                          Assistant brings the power of cognition, simplicity,
                          automation to your IT Helpdesk team.
                        </p>

                        <p class="font-section-normal-text line-height-2">
                          <a href="https://workativ.com/">
                            Try Workativ Assistant now for free.
                          </a>
                        </p>
                      </div>

                      <div className="most_popular_links">
                        <div className="most_popular_header font-section-sub-header-bold">
                          Related Articles
                        </div>
                        <div className="most_popular_ul">
                          <ul className="section__ul">
                            <li>
                              <a href="https://workativ.com/conversational-ai-platform/conversational-ai-chatbot-for-employee-service">
                              Conversational AI Chatbot for employee service automation
                              </a>
                            </li>
                            <li>
                              <a href="https://workativ.com/conversational-ai-platform/top-tools-to-build-conversational-ai-chatbot">
                                {" "}
                                Conversational AI Tools—Top 6 Tools To Build Conversational AI Chatbot
                              </a>
                            </li>
                            <li>
                              <a href="https://workativ.com/conversational-ai-platform/conversational-ai-chatbot ">
                              What Is Conversational AI? A Guide to Conversational AI Chatbots
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>

                      <div className="button_last">
                        <button>
                          <a href="https://blog.workativ.com/category/conversational-ai">
                            Conversational AI
                          </a>
                        </button>
                      </div>
                    </div>
                    <div className="sticky_right_img">
                      <a href="https://workativ.com/conversational-ai-platform">
                        <img src={topImage} alt="slack chatbot" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <OnScrollPopup />
            <GoToPopup /> */}
            <RequestForm isFooterForm={true} />
          </Layout>
        </Container>
      </section>
    </>
  );
}

const NocodeWrapper = () => {
  return (
    <div className="nocode_wrapper mt-0 ">
      <h4>No Code - Free Conversational AI Chatbot </h4>
      <button>
        <a
          href="https://workativ.com/conversational-ai-platform"
          alt="Conversational AI chatbot"
        >
          Get Started
        </a>
      </button>
    </div>
  );
};

const HelpdeskWrapper = (props) => {
  return (
    <div className="nocode_wrapper helpdesk_wrapper">
      <h4>
        <span>
          <img src={require("../../assets/images/arrow_blog.svg")} alt="img" />
        </span>
        {props.header}
      </h4>
    </div>
  );
};
